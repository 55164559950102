<template>
  <banners type="ba" />
</template>

<script>
import banners from './components/banners.vue'
export default {
  components: {
    banners
  }
}
</script>